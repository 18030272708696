import React, { Component } from "react";
import pictures from './pictures.json';



class Photo extends Component {
  render() {

    const imgLeft = pictures.personal_projects.map((path, id) => {
      if (path.placement === 'left')
        return <img alt={path.id} src={path.path} key={id} className={path.size} loading='lazy' />
      return null;
    })

    const imgCenter = pictures.personal_projects.map((path, id) => {
      if (path.placement === 'center')
        return <img alt={id} src={path.path} key={id} className={path.size} loading='lazy' />
      return null;
    })

    const imgRight = pictures.personal_projects.map((path, id) => {
      if (path.placement === 'right')
        return <img alt={id} src={path.path} key={id} className={path.size} loading='lazy' />
      return null;
    })

    return (
      <div className="container">
        <div className="column1">
          <div className="item" >
            {imgLeft}
          </div>
        </div>
        <div className="column2">
          <div className="item" >
            {imgCenter}
          </div>
        </div>
        <div className="column3">
          <div className="item" >
            {imgRight}
          </div>
        </div>
      </div>
    );
  }
}

export default Photo;