import React, { Component } from "react";
import pictures from './pictures.json';



class ProductsPhotography extends Component {
  render() {
    const imgLeft = pictures.propho.map((path, id) => {
      if (path.placement === 'left')
        return <img alt={path.id} src={path.path} key={id} className={path.size} loading='lazy' />
      return null;
    })

    const imgCenter = pictures.propho.map((path, id) => {
      if (path.placement === 'center')
        return <img alt={id} src={path.path} key={id} className={path.size} loading='lazy' />
      return null;
    })

    const imgRight = pictures.propho.map((path, id) => {
      if (path.placement === 'right')
        return <img alt={path.path} src={path.path} key={id} className={path.size} loading='lazy' />
      return null;
    })

  return(
      <div className="container">
      <div className="column1">
        <div className="item" >
          {imgLeft}
        </div>
      </div>
      <div className="column2">
        <div className="item" >
          {imgCenter}
        </div>
      </div>
      <div className="column3">
        <div className="item" >
          {imgRight}
        </div>
      </div>
    </div>
    );
  }
}

export default ProductsPhotography;