import React, { Component } from "react";
import pictures from "./pictures.json";

// function importAll(r) {
//   let images = {};
//   r.keys().map((item, index) => {
//     images[item.replace("./", "")] = r(item);
//   });
//   return images;
// }

class Main extends Component {
  render() {
    // const images = importAll(
    //   require.context("./img", false, /\.(png|jpe?g|svg)$/)
    // );

    const imgLeft = pictures.main.map((path, id) => {
      if (path.placement === "left")
        return (
          <img
            alt={path.path}
            src={path.path}
            key={id}
            className={path.size}
            loading='lazy'
          />
        );
      return null;
    });

    const imgCenter = pictures.main.map((path, id) => {
      if (path.placement === "center")
        return (
          <img
            alt={path.path}
            src={path.path}
            key={id}
            className={path.size}
            loading='lazy'
          />
        );
      return null;
    });

    const imgRight = pictures.main.map((path, id) => {
      if (path.placement === "right")
        return (
          <img
            alt={path.path}
            src={path.path}
            key={id}
            className={path.size}
            loading='lazy'
          />
        );
      return null;
    });

    return (
      <div className='container'>
        {/* <h1>Test</h1> */}
        <div className='column1'>
          <div className='item'>{imgLeft}</div>
        </div>
        <div className='column2'>
          <div className='item'>{imgCenter}</div>
        </div>
        <div className='column3'>
          <div className='item'>{imgRight}</div>
        </div>
      </div>
    );
  }
}

export default Main;
