import logo from "./logo.svg";
import "./css/App.css";
import { Routes, Route, NavLink, HashRouter } from "react-router-dom";

import Photo from "./Photo";
import Contact from "./Contact";
import Analog from "./Analog";
import Nature from "./Nature";
import ProductsPhotography from "./ProductsPhotography";
import Main from "./Home";
import Menu from "./Menu.js";

function App() {
  return (
    <HashRouter>
      <div className='App'>
        <nav>
          <Menu />
        </nav>
        <div className='content'>
          <Routes>
            <Route exact path='/' element={<Main />} />
            <Route path='/photo' element={<Photo />} />
            <Route
              path='/product-photography'
              element={<ProductsPhotography />}
            />
            <Route path='/nature-landscapes' element={<Nature />} />
            <Route path='/analog' element={<Analog />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
        </div>
        <footer>All rights reserved @ Theisse</footer>
      </div>
    </HashRouter>
  );
}

export default App;
