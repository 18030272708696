import React, { Component } from "react";
import "./css/Menu.css";
import { NavLink } from "react-router-dom";
//   import Home from "./Home";
//   import Stuff from "./PersonalProject";
//   import Contact from "./Contact";

class Menu extends Component {
  render() {
    return (
      <div>
        <input className='side-menu' type='checkbox' id='side-menu' />
        <label className='hamb' htmlFor='side-menu'>
          <span className='hamb-line'></span>
        </label>
        <header>
          <span id='t'>Theisse</span> <span id='o'>Helga</span>
        </header>

        {/*  */}
        <div className='Menu'>
          <div className='link'>
            <NavLink to='/'>Main</NavLink>
            {/* <a href='Home.js'>Test</a> */}
          </div>
          <div className='link'>
            <NavLink to='/product-photography'>Product Photography</NavLink>
          </div>
          <div className='link'>
            <NavLink to='/analog'>Analog</NavLink>
          </div>
          <div className='link'>
            <NavLink to='/nature-landscapes'>Nature & Landscapes</NavLink>
          </div>
          {/* <div><NavLink to='/fashion'>Fashion</NavLink></div>
                    <div><NavLink to='/clients'>Clients</NavLink></div> */}
          <div className='link'>
            <NavLink to='/contact'>Price &amp; Contact</NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
