import React, { Component } from "react";

class Contact extends Component {
  render() {
    return (
      <div>
        <h2 className='contactsTitle'>Helga Theisse Photography</h2>
        <p className='details'>
          Helga Theisse Photography is based in Munich, Germany <br />
          <ul>
            <li>Model Test</li>
            <li>Lookbook</li>
            <li>Campaign</li>
            <li>Content for brands</li>
            <li>Personal photoshoots</li>
            <li>Retouching services</li>
          </ul>
          <br />
          <span>
            Contact email address for enquiries{" "}
            <a href='mailto:info@theisse.com'>info@theisse.com</a>{" "}
          </span>{" "}
          <br />
          <br />
          <a
            href='https://instagram.com/theisse.helga'
            target={"_blank"}
            rel='noreferrer'
          >
            <img
              src='./img/social/Instagram_Glyph_Black.png'
              width={"64px"}
              height={"64px"}
              alt='My Instagram'
            />
          </a>
        </p>
      </div>
    );
  }
}

export default Contact;
